import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WelcomeComponent} from './welcome/welcome.component';
import {UserComponent} from './user/user.component';
import {QuestionComponent} from './question/question.component';
import {GameDoneComponent} from './game-done/game-done.component';
import {SteadyComponent} from './steady/steady.component';
import {ReadyComponent} from './ready/ready.component';
import {QuestionierComponent} from './questionier/questionier.component';
import {LeadersComponent} from './leaders/leaders.component';
import {PromoComponent} from './promo/promo.component';
import {UserFinalizeComponent} from './user-finalize/user-finalize.component';
import {PromoDonationComponent} from './promo-donation/promo-donation.component';
import {NotFoundComponent} from './components/not-found/not-found.component';


const routes: Routes = [
  {
    path: '', // login?redirect&redirectParams
    component: WelcomeComponent,
  },
  {
    path: 'user', // login?redirect&redirectParams
    component: UserComponent
  },
  {
    path: 'question', // login?redirect&redirectParams
    component: QuestionComponent
  },
  {
    path: 'game-done', // login?redirect&redirectParams
    component: GameDoneComponent
  },
  {
    path: 'how-to', // login?redirect&redirectParams
    component: SteadyComponent
  },
  {
    path: 'ready', // login?redirect&redirectParams
    component: ReadyComponent
  },
  {
    path: 'anketa', // login?redirect&redirectParams
    component: QuestionierComponent
  },
  {
    path: 'leaders', // login?redirect&redirectParams
    component: LeadersComponent
  },
  {
    path: 'promo', // login?redirect&redirectParams
    component: PromoComponent
  },
  {
    path: 'promo-donation', // login?redirect&redirectParams
    component: PromoDonationComponent
  },
  {
    path: 'user-finalize', // login?redirect&redirectParams
    component: UserFinalizeComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
