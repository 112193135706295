import {Component, Input, OnInit} from '@angular/core';
import {EverythingService} from '../everything.service';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss']
})
export class LeaderBoardComponent implements OnInit {

  @Input() leaderBoard = null;
  @Input() startWith = 0;

  public user = null;

  constructor(private et: EverythingService) { }

  ngOnInit() {
    this.user = this.et.getUser();
  }

}
