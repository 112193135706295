import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EverythingService {

  private user;
  private trainingDone = false;

  constructor() {
    let user = null;
    try {
      user = sessionStorage.getItem('user');
    } catch (e) {
      console.log(e);
    }
    if (user) {
      this.user = JSON.parse(user);
    }
  }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user = user;
    try {
      sessionStorage.setItem('user', JSON.stringify(user));
    } catch (e) {
      console.log('Storage access denied');
    }
  }

  setTrainingDone(value) {
    this.trainingDone = value;
  }

  getTrainingDone() {
    return this.trainingDone;
  }
}
