import {Component, OnInit} from '@angular/core';
import {slideLeft} from '../_animations/slide-left';
import {pageTransition} from '../_animations/page-transition';

declare let gtag: Function;

@Component({
  selector: 'app-steady',
  templateUrl: './steady.component.html',
  styleUrls: ['./steady.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition
  ]
})
export class SteadyComponent implements OnInit {

  public step = 1;

  private steps = {
    1: {
      title: 'Pokaži svoje znanje o Sloveniji',
    },
    2: {
      title: 'Ura teče, nič ne reče',
    },
    3: {
      title: 'Če se zatakne, si pomagaj',
    },
    4: {
      title: 'Lestvica najblojših',
    }
  }

  constructor() {
  }

  ngOnInit() {
    this.track();
  }

  nextStep() {
    this.step++;
    this.track();
  }

  track() {
    gtag('event', 'page_view', {
      page_title: 'Navodila - ' + this.steps[this.step].title,
      page_path: '/navodila/' + this.step
    });
  }

}
