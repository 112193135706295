import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpCacheService} from './http-cache.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Quiz} from '../models/quiz';
import {map} from 'rxjs/operators';
import {QuizService} from './quiz.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient, private quizService: QuizService) {
  }

  getNextQuestion(training = null , boosters = null): Observable<any> {

    let url = `/quiz/${this.quizService.getQuizToken()}/question/next`;

    if (boosters) {
      if (boosters.oneMoreTime) {
        url += `?booster=onemoretime`;
      }
    } else {
      boosters.oneMoreTime = false;
    }

    return this.http.get(url, {
      params: {
        booster: boosters.oneMoreTime ? 'onemoretime' : '',
        training: training ? training : '',
      }
    });
  }
}
