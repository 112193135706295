import {animate, style, transition, trigger} from '@angular/animations';

export const slideLeft =
  trigger(
    'slideLeft',
    [
      transition(
        ':enter',
        [
          style({transform: 'translateX(100vw)', position: 'absolute', left: 0, right: 0, margin: 'auto'}),
          animate('.5s ease-in-out',
            style({transform: 'translateX(0vw)'}))
        ]
      ),
      transition(
        ':leave',
        [
          style({transform: 'translateX(0vw)', position: 'absolute', left: 0, right: 0, margin: 'auto'}),
          animate('.5s ease-in-out',
            style({transform: 'translateX(-100vw)'}))
        ]
      )
    ]
  );
