import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {EverythingService} from '../everything.service';
import {SoundService} from '../sound.service';
import {Quiz} from '../models/quiz';
import {QuizService} from '../services/quiz.service';

import {TrackService} from '../services/track.service';

declare let gtag: Function;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [
    trigger(
      'slideUp',
      [
        transition(
          ':enter',
          [
            style({transform: 'translateY(-100%)', opacity: 1}),
            animate('.5s ease-in-out',
              style({transform: 'translateY(0%)', opacity: 1}))
          ]
        ),


        
        transition(
          ':leave',
          [
            style({transform: 'translateY(0%)', opacity: 1}),
            animate('.5s ease-in-out',
              style({transform: 'translateY(-100%)', opacity: 1}))
          ]
        )
      ]
    ),
  ]
})
export class TopBarComponent implements OnInit, OnChanges {

  @Input() minimal = false;
  @Input() buttonLabel = 'Igraj naprej';

  @Input() questionNum = null;
  @Input() timeLeft = 30;

  @Input() score = 0;
  @Input() position = 1;
  @Input() newPoints: any = false;

  @Input() infoEnabled = false;
  @Input() maxQuestions = 15;

  @Input() invisible = false;

  @Output() infoClicked = new EventEmitter<string>();

  public newPointsDown: any = false;
  public scoreUp: any = false;

  private scrollTimer$ = null;
  private timerSpeed = 30;

  public user = null;

  public infoVisible = false;
  public prizesVisible = false;
  public termsVisible = false;
  public emailVisible = false;

  public muted = false;

  public quiz: Quiz = null;

  public prizes = [
    {
      id: '1. mesto',
      title: 'Darilna kartica Lidl v vrednosti 150 €',
      img: 'nagrada_1.png',
      desc: '',
      cta: ''
    },
    {
      id: '2. mesto',
      title: 'Darilna kartica Lidl v vrednosti 100 €',
      img: 'nagrada_2.png',
      desc: '',
      cta: ''
    },
    {
      id: '3. mesto',
      title: 'Darilna kartica Lidl v vrednosti 50 €',
      img: 'nagrada_3.png',
      desc: '',
      cta: ''
    },
    {
      id: '4. - 10. mesto',
      title: 'Darilna kartica Lidl v vrednosti 20 €',
      img: 'nagrada_4.png',
      desc: '',
      cta: ''
    },
  ];





  constructor(
    private et: EverythingService,
    private ss: SoundService,
    private quizService: QuizService,
    public track: TrackService,
  ) {
  }

  ngOnInit() {
    this.user = this.et.getUser();
    this.ss.getMute().subscribe((v) => {
      this.muted = v;
    });


    this.quizService.getQuiz().subscribe((quiz) => {
      this.quiz = quiz;
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.newPoints) {
      if (changes.newPoints.currentValue) {
        this.newPointsDown = changes.newPoints.currentValue;
        this.startTimer();
      }
    }

    if (changes.score) {
      if (this.scoreUp === false) {
        this.scoreUp = changes.score.currentValue;
      }
      if (changes.score.currentValue) {
        // this.scoreUp = changes.score.currentValue;
        // this.startTimer();
      }
    }
  }

  startTimer() {

    const timerStep = 50;
    this.timerSpeed = Math.ceil(this.newPointsDown / 2800 * timerStep);


    if (!this.scrollTimer$) {
      this.scrollTimer$ = setInterval(() => {
        this.newPointsDown = this.newPointsDown - this.timerSpeed;
        this.scoreUp = this.scoreUp + this.timerSpeed;
        if (this.newPointsDown < 1) {
          this.scoreUp = this.score;
          this.newPointsDown = false;
          clearInterval(this.scrollTimer$);
          this.scrollTimer$ = null;
        }
      }, timerStep);

    }
  }

  toggleInfo(force = null) {
    this.infoClicked.next();
    if (force !== null) {
      this.infoVisible = force;
    } else {
      this.closeModal();
      this.infoVisible = !this.infoVisible;
    }
    if (this.infoVisible) {
      gtag('event', 'page_view', {
        page_title: 'Info',
        page_path: '/info',
      });
    }
  }

  showModal(modal) {
    this.closeModal();
    if (modal === 'prizes') {
      this.prizesVisible = true;
      gtag('event', 'page_view', {
        page_title: 'Nagrade',
        page_path: '/nagrade',
      });
    }
    if (modal === 'terms') {
      this.termsVisible = true;
      gtag('event', 'page_view', {
        page_title: 'Pogoji',
        page_path: '/poogji',
      });
    }
    if (modal === 'email') {
      this.emailVisible = true;
      gtag('event', 'page_view', {
        page_title: 'Email',
        page_path: '/email',
      });
    }
  }

  closeModal() {
    this.termsVisible = false;
    this.prizesVisible = false;
    this.emailVisible = false;
  }

  mute() {
    this.ss.muteEffects();
  }

  unMute() {
    this.ss.unMuteEffects();
  }



}
