import {Injectable} from '@angular/core';
import {isObject} from 'util';

declare let gtag: Function;
declare let plausible: Function;
@Injectable({
  providedIn: 'root'
})

export class TrackService {

  constructor() {
  }

  gaTrackPageView(title: string, path: string): void {
    const data: {
      page_title?: string;
      page_path?: string;
    } = {};

    if (title) {
      data.page_title = title;
    }

    if (path) {
      data.page_path = path;
    }

    gtag('event', 'page_view', data);
  }


  gaTrackAction(action: string, category?: string | {}, label?: string, value?: string): void;
  gaTrackAction(action: string, data: {}): void;
  gaTrackAction(action = 'Default Action', data?: any | {}, label?: any, value?: any): void {
    if (!isObject(data)) {
      const newData: {
        event_category?: string;
        event_label?: string;
        event_value?: string;
      } = {};

      if (data) {
        newData.event_category = data;
      }

      if (label) {
        newData.event_label = label;
      }

      if (value) {
        newData.event_value = value;
      }
      data = newData;
    }
    gtag('event', action, data);
    plausible(action, {props: data})

  }
}
