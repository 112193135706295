import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {TopBarComponent} from '../top-bar/top-bar.component';
import {pageTransition} from '../_animations/page-transition';
import {TrackService} from '../services/track.service';
import {animate, style, transition, trigger, query, stagger, state} from '@angular/animations';

declare let gtag: Function;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,

    trigger("myAnimationTrigger", [
      state('hidden',style({
        transform: 'translateY(-20px)',
        opacity: 0,
      })),
      state('shown',style({
        transform: 'translateY(0)',
        opacity: 1
      })),

      transition('hidden => shown', animate('600ms {{delay}}ms ease-in'), {params: {delay: '600'}}),
      
    ]),



  ]
})
export class WelcomeComponent implements OnInit {
  public step = 2;

  state = 'hidden';

  @ViewChild(TopBarComponent, {static: false}) topBar: TopBarComponent;


  constructor(
    public track: TrackService
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.state = 'shown';
    }, 0);


    gtag('event', 'page_view', {
      page_title: 'Frontpage',
    });


  }

  showPrizes() {
    this.topBar.showModal('prizes');
  }
}
