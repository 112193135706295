import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {IntroComponent} from './intro/intro.component';
import {SteadyComponent} from './steady/steady.component';
import {UserComponent} from './user/user.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {QuestionComponent} from './question/question.component';
import {AuthInterceptor} from './http-interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TopBarComponent} from './top-bar/top-bar.component';
import {BottomBarComponent} from './bottom-bar/bottom-bar.component';
import {LeaderBoardComponent} from './leader-board/leader-board.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {GameDoneComponent} from './game-done/game-done.component';
import {SoundDirective} from './sound.directive';
import {ReadyComponent} from './ready/ready.component';
import {QuestionierComponent} from './questionier/questionier.component';
import {LeadersComponent} from './leaders/leaders.component';

import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import { PromoComponent } from './promo/promo.component';
import { UserFinalizeComponent } from './user-finalize/user-finalize.component';
import { NotFoundComponent } from './components/not-found/not-found.component'
import { PromoDonationComponent } from './promo-donation/promo-donation.component'

Bugsnag.start({apiKey: 'c12eac151a7088060969868c4aadf150', generateAnonymousId: true, collectUserIp: true});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  //suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    IntroComponent,
    SteadyComponent,
    UserComponent,
    QuestionComponent,
    TopBarComponent,
    BottomBarComponent,
    LeaderBoardComponent,
    GameDoneComponent,
    SoundDirective,
    ReadyComponent,
    QuestionierComponent,
    LeadersComponent,
    PromoComponent,
    UserFinalizeComponent,
    NotFoundComponent,
    PromoDonationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    PerfectScrollbarModule
  ],
  providers: [
    {provide: ErrorHandler, useFactory: errorHandlerFactory},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
