import {Component, OnInit, ViewChild} from '@angular/core';
import {EverythingService} from '../everything.service';
import {HttpClient} from '@angular/common/http';
import {SoundService} from '../sound.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {TopBarComponent} from '../top-bar/top-bar.component';
import {ShareService} from '../services/share.service';
import {pageTransition} from '../_animations/page-transition';
import {containerSlideUp} from '../_animations/slide-up';
import {TrackService} from '../services/track.service';

declare let gtag: Function;

@Component({
  selector: 'app-promo-donation',
  templateUrl: './promo-donation.component.html',
  styleUrls: ['./promo-donation.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,
    containerSlideUp,
  ]
})




export class PromoDonationComponent implements OnInit {

  constructor(

    private et: EverythingService,
    private http: HttpClient,
    private ss: SoundService,
    private router: Router,
    private shareService: ShareService,
    public track: TrackService,


  ) { }

  ngOnInit() {

    gtag('event', 'page_view', {
      page_title: 'Donacije',
      page_path: '/promo-donation',
    });

  }


  share(type: string) {
    this.track.gaTrackAction('share', 'social_share', 'donation');
    this.shareService.share(type);
  }

}
