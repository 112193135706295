import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  private cachedItems = [];

  constructor() {
  }

  get(key, request: Observable<any>, reset = false) {
    const cachedItem = this.cachedItems.find((item) => {
      return item.key === key;
    });

    if (cachedItem && !reset) {
      return of(cachedItem.value);
    }

    return request.pipe(tap((response) => {
      this.cachedItems.push({
        key,
        value: response,
      })
    }));
  }
}
