import {Component, OnInit} from '@angular/core';
import {EverythingService} from '../everything.service';
import {slideLeft} from '../_animations/slide-left';
import {containerSlideUp} from '../_animations/slide-up';
import {pageTransition} from '../_animations/page-transition';

declare let gtag: Function;

@Component({
  selector: 'app-ready',
  templateUrl: './ready.component.html',
  styleUrls: ['./ready.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,
    containerSlideUp
  ]
})
export class ReadyComponent implements OnInit {

  public trainingDone = false;

  constructor(private et: EverythingService) {
    this.trainingDone = this.et.getTrainingDone();
  }

  ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Pripravljeni?',
      page_path: '/pripravljeni',
    });
  }

  setDone() {
    this.trainingDone = true;
    this.et.setTrainingDone(true);
  }

}
