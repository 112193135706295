import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
  animations: [
    trigger(
      'slideUp',
      [
        transition(
          ':enter',
          [
            style({transform: 'translateY(100%)', opacity: 1}),
            animate('.5s ease-in-out',
              style({transform: 'translateY(0%)', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'translateY(0%)', opacity: 1}),
            animate('.5s ease-in-out',
              style({transform: 'translateY(100%)', opacity: 1}))
          ]
        )
      ]
    ),
    trigger(
      'scaleUp',
      [
        transition(
          ':enter',
          [
            style({transform: 'translate(5%, 20%) scale(0)', opacity: 1}),
            animate('.3s ease-in-out',
              style({transform: 'translate(0%, 0%) scale(1)', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'translate(0%, 0%) scale(1)', opacity: 1}),
            animate('.3s ease-in-out',
              style({transform: 'translate(5%, 20%) scale(0)', opacity: 1}))
          ]
        )
      ]
    ),
  ]
})
export class BottomBarComponent implements OnInit, OnChanges {

  @Input() multipleQuestion = false;
  @Input() boosters: any = null;
  @Input() answerSelected = false;
  @Input() showBoosters = true;

  @Output() verifyAnswers = new EventEmitter<boolean>();
  @Output() useBooster = new EventEmitter<string>();

  public activeBooster = null;
  public activeBoosterWindow = null;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  btnClick() {
    this.verifyAnswers.next();
  }

  booster(event, booster) {
    event.stopPropagation();
    this.activeBooster = booster;
    this.useBooster.next(booster);
    this.activeBoosterWindow = null;
  }

  toggleBooster(booster) {
    if (booster === this.activeBoosterWindow) {
      this.activeBoosterWindow = null;
    } else {
      this.activeBoosterWindow = booster;
    }
  }

}
