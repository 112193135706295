import {Component, OnInit, ViewChild} from '@angular/core';
import {EverythingService} from '../everything.service';
import {HttpClient} from '@angular/common/http';
import {SoundService} from '../sound.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {TopBarComponent} from '../top-bar/top-bar.component';
import {ShareService} from '../services/share.service';
import {pageTransition} from '../_animations/page-transition';
import {containerSlideUp} from '../_animations/slide-up';
import {TrackService} from '../services/track.service';

declare let gtag: Function;

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,
    containerSlideUp,
  ]
})






export class PromoComponent implements OnInit {

  public user = null;
  public leaderBoard = [];
  public meBoard = null;
  public stats: any = null;
  public loading = true;

  public email = null;
  public emailError: any = false;
  public formSent = false;
  public showForm = false;

  @ViewChild(TopBarComponent, {static: false}) topBar: TopBarComponent;


  constructor(
    private et: EverythingService,
    private http: HttpClient,
    private ss: SoundService,
    private router: Router,
    private shareService: ShareService,
    public track: TrackService,
  ) {
  }

  ngOnInit() {


    const buttonClipboard: HTMLInputElement  = document.getElementById('toclipboard') as HTMLInputElement;
    const buttonCoupon: HTMLInputElement  = document.getElementById('couponCode') as HTMLInputElement;

    buttonClipboard.addEventListener('click', function(e) {
      e.preventDefault();
      // @ts-ignore
      document.execCommand('copy', false, buttonCoupon.select());

      (<HTMLElement>document.querySelector('.copy-notify')).style.display = 'block';

    });


    gtag('event', 'page_view', {
      page_title: 'Promo darilo',
      page_path: '/promo',
    });

    this.user = this.et.getUser();
    this.ss.playWin();
    this.loading = true;

    this.http.get(`${environment.api}/game`).subscribe((game: any) => {
      this.stats = game;
      if (!game.game_done) {
        this.router.navigate(['/']);
      }

    }, (error) => {
      this.router.navigate(['/']);
    });
  }





  share(type: string) {
    this.track.gaTrackAction('share', 'social_share', 'darilo');
    this.shareService.share(type);
  }

  showEmail() {
    this.showForm = true;
  }

  sendForm() {
    this.emailError = false;
    this.formSent = false;

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.email || this.email === '') {
      this.emailError = 'E-Mail ist erforderlich';
    }

    if (!re.test(String(this.email).toLowerCase())) {
      this.emailError = 'E-Mail ungültig';
    }

    if (!this.emailError) {
      this.http.post(`/user/sendMail`, {
        email: this.email
      }).subscribe((resp: any) => {
        this.formSent = true;
        this.track.gaTrackAction('posiljanje-kupona');
      }, (errorr) => {
        this.formSent = false;
        this.emailError = errorr.error;
      });
    }
  }

}
