import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SoundService} from './sound.service';

@Directive({
  selector: '[appSound]'
})
export class SoundDirective implements OnChanges {

  @Input() appSound = ['click'];
  private events = ['click'];

  constructor(el: ElementRef, private ss: SoundService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appSound) {
      if (changes.appSound.currentValue === false) {
        this.events = [];
      } else if (changes.appSound.currentValue === null || changes.appSound.currentValue === true) {
        this.events = ['click'];
      } else {
        this.events = changes.appSound.currentValue;
      }
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.events.indexOf('hover') !== -1) {
      //this.ss.playHover();
    }
  }

  /*
  @HostListener('touchstart') onTouchStart() {
    if (this.events.indexOf('hover') !== -1) {
      this.ss.playHover();
    }
  }
  */

  @HostListener('mouseup') onMouserUp() {
    if (this.events.indexOf('click') !== -1) {
      //this.ss.playClick();
    }
    if (this.events.indexOf('swipe') !== -1) {
      //this.ss.playSwipe();
    }
  }

}
