import {animate, style, transition, trigger} from '@angular/animations';

export const pageTransition =
  trigger(
    'pageTransition',
    [
      transition(
        ':enter',
        [
          style({position: 'absolute', left: 0, right: 0, opacity: 0}),
          animate('.5s ease-in-out',
            style({opacity: 1}))
        ]
      ),
      transition(
        ':leave',
        [
          style({position: 'absolute', left: 0, right: 0, opacity: 1}),
          animate('.5s ease-in-out',
            style({opacity: 0}))
        ]
      )
    ]
  );
