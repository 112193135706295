import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EverythingService} from '../everything.service';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {QuizService} from '../services/quiz.service';
import {Quiz} from '../models/quiz';
import {pageTransition} from '../_animations/page-transition';
import {TrackService} from '../services/track.service';

declare let gtag: Function;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({transform: 'translateY(0%) scale(0)', opacity: 0}),
            animate('.25s ease-in-out',
              style({transform: 'translateY(0%) scale(1)', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'translateY(0%) scale(1)', opacity: 1}),
            animate('.25s ease-in-out',
              style({transform: 'translateY(0%) scale(0)', opacity: 0}))
          ]
        )
      ]
    ),
  ],
})
export class UserComponent implements OnInit {



  public userData = {};

  public formSent = false;
  public formError: boolean | string = false;

  public quiz: Quiz = null;

  constructor(

    private http: HttpClient,
    private et: EverythingService,
    private router: Router,
    private quizService: QuizService,
    public track: TrackService,
  ) {
    this.quizService.getQuiz().subscribe((res) => {

      this.quiz = res;

      // Check if any fields for user input exists else skip this step
      if(this.quiz.settings.login.fields.length === 0 ) {

        if (this.et.getUser() == undefined) {
          this.createUser()
        } else {
          this.router.navigate(['/question']);
        }


      }

      
      
      this.resetForm();
    });
  }

  ngOnInit() {
    /*
    gtag('event', 'page_view', {
      page_title: 'Prijava uporabnika',
      page_path: '/prijava',
    });
    */
  }

  resetForm() {
    this.quiz.settings.login.fields.forEach((field) => {
      if (!this.userData[field.name]) {
        this.userData[field.name] = {
          error: false,
          value: '',
        };
      } else {
        this.userData[field.name].error = false;
      }
    });
  }

  createUser() {

      this.formSent = true;
      if (this.quiz.settings.login.method === 'guest') {

        const postData = {
          guest: true,
          quizId: this.quiz.token,
        };

        this.quiz.settings.login.fields.forEach((field) => {
          let propertyName = field.name;
          if (field.mapTo) {
            propertyName = field.mapTo;
          }

          postData[propertyName] = this.userData[field.name].value;
        });

        this.http.post(`/user`, postData)
          .subscribe((resp: any) => {
            this.formSent = false;
            this.et.setUser(resp);
            // gtag('event', 'prijava_uporabnika', {newsletter: this.newsletter});
            //this.track.gaTrackAction('ime', 'cta', 'pozdrav');
            if (resp.games_done >= 2) {
              this.router.navigate(['/leaders']);
            } else {
              this.router.navigate(['/question']);
            }
          }, (errorr) => {
            this.formSent = false;
            //this.formError = 'Prišlo je do napake';

            if (errorr.error && errorr.error.error) {
              switch (errorr.error.error) {
                case 'max_games':
                  this.router.navigate(['/game-done']);
                  break;
                case 'name_exists':
                  this.formError = `Der Name ist leider schon vergeben.`;
                  break;
                default:
                  this.formError = 'Prišlo je do napake';
                  break;
              }
            } else {
              this.formError = 'Prišlo je do napake';
            }
          });
      }
  }

  sendForm() {
    let error = false;
    this.formError = false;
    this.resetForm();

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.quiz.settings.login.fields.forEach((field) => {
      const userField = this.userData[field.name];
      if (field.required) {
        if (!userField.value || userField.value === '') {
          error = true;
          userField.error = 'Pflichtfeld';
        }
      }

      if (field.minLength) {
        if (userField.value && userField.value.length <= field.minLength) {
          error = true;
          userField.error = `Geben Sie mindestens ${field.minLength} Zeichen ein`;
        }
      }

      if (field.maxLength) {
        if (userField.value && userField.value.length > field.maxLength) {
          error = true;
          userField.error = `Geben Sie maximal ${field.maxLength} Zeichen ein`;
        }
      }

      if (field.type === 'email') {
        if (!re.test(String(userField.value).toLowerCase())) {
          error = true;
          userField.error = 'E-Mail ungültig';
        }
      }
    });

    if (!error) {
      this.createUser();
    }
  }
}
