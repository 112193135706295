import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {containerSlideUp} from '../_animations/slide-up';
import {slideLeft} from '../_animations/slide-left';
import {environment} from '../../environments/environment';
import {timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EverythingService} from '../everything.service';
import {Router} from '@angular/router';
import {pageTransition} from '../_animations/page-transition';


declare let gtag: Function;

@Component({
  selector: 'app-questionier',
  templateUrl: './questionier.component.html',
  styleUrls: ['./questionier.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {'[@pageTransition]': ''},
  animations: [
    trigger('slideIn', [
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({opacity: 0, transform: 'translateY(50%)'}),
          stagger(100, [
            animate('0.3s ease-out', style({opacity: 1, transform: 'translateY(0%)'}))
          ])
        ])
      ])
    ]),
    containerSlideUp,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({transform: 'scale(3)', opacity: 1}),
            animate('.25s ease-in-out',
              style({transform: 'scale(1)', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'scale(1)', opacity: 1}),
            animate('.25s ease-in-out',
              style({transform: 'scale(3)', opacity: 0}))
          ]
        )
      ]
    ),
    trigger(
      'slideLeftOut',
      [
        transition(
          ':leave',
          [
            style({transform: 'translateX(0%)', position: 'absolute'}),
            animate('.5s ease-in-out',
              style({transform: 'translateX(-100%)'}))
          ]
        )
      ]
    ),
    pageTransition,
    slideLeft,
  ],
})
export class QuestionierComponent implements OnInit {



  public questions = [
    {
      id: 1,
      name: 'Wohnt ihr in einem Haus oder in einem Wohnblock?',
      multiple: false,
      answers: [
        {
          id: 1,
          name: 'Im Haus',
        },
        {
          id: 2,
          name: 'Im Wohnblock',
        },
      ]
    },
    {
      id: 2,
      name: 'Habt ihr einen Garten?',
      multiple: false,
      answers: [
        {
          id: 1,
          name: 'Ja',
        },
        {
          id: 2,
          name: 'Nein',
        },
      ],
    },
    {
      id: 3,
      name: 'Trennt ihr biologische Abfälle?',
      multiple: false,
      answers: [
        {
          id: 1,
          name: 'Ja',
        },
        {
          id: 2,
          name: 'Nein',
        },
      ],
    },
  ];

  public question = null;
  public thankYou = false;
  public answerSelected = false;
  public verifyInProgress = false;
  private user = null;

  constructor(private router: Router, private http: HttpClient, private et: EverythingService) {
  }

  ngOnInit() {
    this.user = this.et.getUser();
    if (!this.user) {
      this.router.navigate(['/']);
    } else {
      if (!this.user.questioner) {
        this.question = this.questions[0];
      } else {
        const ext = this.user.questioner;
        if (ext[1]) {
          if (ext[1][0] === 1) {
            this.question = this.questions[1];
          } else {
            this.question = this.questions[2];
          }
        }
      }
    }

    gtag('event', 'page_view', {
      page_title: 'Anketa - ' + this.question.id,
      page_path: '/' + this.question.id,
    });
  }



  selectAnswer(answer) {
    this.answerSelected = true;
    if (this.verifyInProgress) {
      return;
    }
    answer.selected = !answer.selected;
    if (!this.question.multiple) {
      this.verifyAnswers();
    }
  }

  verifyAnswers(timeOut = false) {
    this.verifyInProgress = true;
    this.question.multiple = false;
    this.answerSelected = false;

    const answersBody = this.question.answers.filter((ans: any) => {
      return ans.selected;
    }).map((ans) => {
      return ans.id;
    });


    this.http.post(`${environment.api}/questioner`, {
      id: this.question.id,
      answers: answersBody,
    }).subscribe((response: any) => {
      this.et.setUser(response);
      this.verifyInProgress = false;

      gtag('event', 'page_view', {
        page_title: 'Anketa - ' + this.question.id + ' - Hvala',
        page_path: '/' + this.question.id + '/hvala',
      });

      this.router.navigate(['/question']);

      /*
      this.et.setUser(response);
      this.thankYou = true;
      this.verifyInProgress = false;

      gtag('event', 'page_view', {
        page_title: 'Anketa - ' + this.question.id + ' - Hvala',
        page_path: '/' + this.question.id + '/hvala',
      });

      setTimeout(() => {
        this.router.navigate(['/question']);
      }, 3000);
       */
    }, (e) => {
      this.verifyInProgress = false;
    });
  }

}
