import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpCacheService} from './http-cache.service';
import {Observable} from 'rxjs';
import {Quiz} from '../models/quiz';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private http: HttpClient, private httpCache: HttpCacheService) {
  }

  getQuizToken(): string {
    return environment.quizToken;
  }

  getQuiz(reset = false): Observable<Quiz> {
    return this.httpCache.get(`/quiz/${this.getQuizToken()}`,
      this.http.get(`/quiz/${this.getQuizToken()}`).pipe(map((r: Quiz) => {
        /*r.settings = {
          maxPoints: 1000,
          minPoints: 150,
          wrongAnswerPoints: 100,
          randomizeQuestions: false,
          maxGames: 3,
          totalQuestions: 11,
          questionTimeLimit: null,
          scoreBoard: null,
          countDown: null,
          singleTrivia: true,
          resultSegments: [
            {
              from: 0,
              to: 20,
              name: 'Vroči feferon',
            },
            {
              from: 21,
              to: 40,
              name: 'Vroči feferon 2',
            },
            {
              from: 41,
              to: 60,
              name: 'Vroči feferon 3',
            },
            {
              from: 61,
              to: 100,
              name: 'Vroči feferon 4',
            }
          ],
          login: {
            method: 'guest',
            fields: [
              {
                type: 'text',
                name: 'name',
                minLength: 2,
                maxLength: 20,
                required: true,
                placeHolder: 'Ime ali vzdevek',
              },
              /*
              {
                type: 'email',
                name: 'email',
                required: true,
                placeHolder: 'Email za obvestilo o nagradi',
              }*//*
            ],
          }
        };
        console.log(r);*/
        return r;
      })), reset);
  }
}
