import {Component, OnInit} from '@angular/core';
import {EverythingService} from '../everything.service';
import {HttpClient} from '@angular/common/http';
import {SoundService} from '../sound.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {slideLeft} from '../_animations/slide-left';
import {containerSlideUp} from '../_animations/slide-up';
import {pageTransition} from '../_animations/page-transition';
import {TrackService} from '../services/track.service';

declare let gtag: Function;

@Component({
  selector: 'app-leaders',
  templateUrl: './leaders.component.html',
  styleUrls: ['./leaders.component.scss'],
  host: {'[@pageTransition]': ''},
  animations: [
    pageTransition,
    containerSlideUp,
  ]
})
export class LeadersComponent implements OnInit {

  public user = null;
  public leaderBoard = [];
  public meBoard = null;
  public stats: any = null;
  public loading = true;

  constructor(
    private et: EverythingService,
    private http: HttpClient,
    private ss: SoundService,
    private router: Router,
    public track: TrackService,
  ) {
  }

  ngOnInit() {
    this.user = this.et.getUser();
    //this.ss.playWin();
    this.loading = true;

    gtag('event', 'page_view', {
      page_title: 'Zmagovalci',
      page_path: '/zmagovalci',
    });

    if (this.user) {
      this.http.get(`${environment.api}/game`).subscribe((game: any) => {
        this.stats = game;
        if (!game.game_done) {
          this.router.navigate(['/']);
        }

        this.http.get(`${environment.api}/leader-board/final`).subscribe((leaderBoard: any) => {
          let meFound = false;
          this.loading = false;
          this.leaderBoard = leaderBoard.filter((board) => {
            if (meFound && board.user_id === this.user.id) {
              //return false;
            }
            if (board.user_id === this.user.id) {
              meFound = true;
            }
            return true;
          });
          if (leaderBoard.length > 0) {
            debugger;
            this.meBoard = leaderBoard.find((board) => {
              return board.user_id === this.user.id && board.score === this.stats.score;
            });
            if (!this.meBoard) {
              this.meBoard = leaderBoard.find((board) => {
                return board.user_id === this.user.id;
              });
            }
          }
        });
      }, (error) => {
        this.router.navigate(['/']);
      });
    } else {
      this.http.get(`${environment.api}/leader-board/final`).subscribe((leaderBoard: any) => {
        this.loading = false;
        this.leaderBoard = leaderBoard;
      });
    }
  }

  share() {
    this.track.gaTrackAction('social_share', 'Share');
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=https://www.lidl.si/tvoja-izbira/kviz&display=popup&ref=plugin&src=like&kid_directed_site=0',
      'share', 'width=526,height=550,toolbar=0,titlebar=0,status=0,menubar=0,location=0', true)
  }

}
