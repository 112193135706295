import {Injectable} from '@angular/core';
import {Howl} from 'howler';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  /*
  private background = new Howl({
    src: ['/assets/sound/background.mp3'],
    autoplay: false,
    loop: true,
    volume: 0.3,
  });
  */

  private success = new Howl({
    src: ['/assets/sound/answer-success.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private error = new Howl({
    src: ['/assets/sound/answer-error.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private hover = new Howl({
    src: ['/assets/sound/hover.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private win = new Howl({
    src: ['/assets/sound/win.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private reveal = new Howl({
    src: ['/assets/sound/reveal.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private click = new Howl({
    src: ['/assets/sound/click.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private timer = new Howl({
    src: ['/assets/sound/timer.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private swipe = new Howl({
    src: ['/assets/sound/swipe.mp3'],
    autoplay: false,
    loop: false,
    volume: 1,
  });

  private mute = false;
  private mute$ = new BehaviorSubject(false);

  constructor() {

  }
/*
  startBackground() {
    if (!this.background.playing()) {
      this.background.play();
    }
  }

  stopBackground() {
    this.background.stop();
  }
*/
  playSuccess() {
    if (!this.mute) {
      this.success.play();
    }
  }

  playError() {
    if (!this.mute) {
      this.error.play();
    }
  }

  playHover() {
    if (!this.mute) {
      this.hover.play();
    }
  }

  playReveal() {
    if (!this.mute) {
      this.reveal.play();
    }
  }

  playWin() {
    if (!this.mute) {
      this.win.play();
    }
  }

  playClick() {
    if (!this.mute) {
      this.click.play();
    }
  }

  playSwipe() {
    if (!this.mute) {
      this.swipe.play();
    }
  }

  playTimer() {
    if (!this.mute) {
      this.timer.play();
    }
  }

  muteEffects() {
    this.mute = true;
    this.mute$.next(true);
  }

  unMuteEffects() {
    this.mute = false;
    this.mute$.next(false);
  }

  getMute() {
    return this.mute$;
  }
}
