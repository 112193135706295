import {Component} from '@angular/core';
import {QuizService} from './services/quiz.service';
import {Router} from '@angular/router';

//import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private quizService: QuizService, private router: Router) {

    this.quizService.getQuiz().subscribe((a) => {
    }, (e) => {
      this.router.navigate(['404']);
    });

    //Bugsnag.notify(new Error('Test error'));
    /*const appHeight = () => {
      const doc = document.documentElement;
      const screen = screen
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();*/
  }
}
